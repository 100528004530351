define('m07/components/screw-tablehead', ['exports', 'ember', 'ember-i18n'], function (exports, Ember, ember_i18n) {

  'use strict';

  var inject = Ember['default'].inject;

  var Multiselectbox = Ember['default'].Component.extend({

    tagName: 'th',
    layoutName: 'screw-tablerhead',
    attributeBindings: [],
    classNames: ['systemtablehead'],

    displayedArrow: "",
    hideUpArrow: false,
    hideDownArrow: false,
    hideAuswahl: true,
    alleschrauben: true,

    i18n: inject.service(),

    text: (function () {
      var self = this;

      var properties = [{
        id: "VM_Typ",
        text: self.get('i18n').t("typ")
      }, {
        id: "Kopfform",
        text: self.get('i18n').t("kopf")
      }, {
        id: "d_1",
        text: "&nbsp;&nbsp;&nbsp;&#8960;&nbsp;&nbsp;&nbsp;"
      }, {
        id: "l_s",
        text: "&nbsp;&nbsp;&nbsp;l&nbsp;&nbsp;&nbsp;"
      }];

      return properties.findBy('id', self.get('parameter')).text;
    }).property('foobar'),

    actions: {

      toggleAuswahl: function toggleAuswahl() {
        this.set("hideAuswahl", !this.get("hideAuswahl"));
      },

      sortUp: function sortUp(_param) {
        this.set('hiddenArrow', "Up");
        this.set('down', true);
        this.sendAction("controllerSortUp", _param);
      },

      sortDown: function sortDown(_param) {
        this.set('hiddenArrow', "Down");
        this.sendAction("controllerSortDown", _param);
      },

      thfilter: function thfilter(_parameter, _id, _checked) {
        this.sendAction("cfilter", _parameter, _id, _checked);
      }
    },

    thtoggkleAllChecked: (function () {
      var self = this;
      this.sendAction("cToggkleAllChecked", self.get('parameter'), self.get('alleschrauben'));
    }).observes('alleschrauben')
  });

  exports['default'] = Multiselectbox;

});