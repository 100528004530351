define('m07/components/multiselect-box', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Multiselectbox = Ember['default'].Component.extend({

    tagName: 'div',
    layoutName: 'multiselect-box',
    attributeBindings: [],
    classNames: ['multiSelectBox'],

    objekte: (function () {

      // console.log(this.get('parameterobjekte'));
      // console.log(this.get('parameterobjekte').findBy('id', this.get('parameter')));
      // console.log(this.get('parameterobjekte').findBy('id', this.get('parameter')).values[0].id);
      // console.log( parseFloat( this.get('parameterobjekte').findBy('id', this.get('parameter')).values[0].id )  );
      // console.log(isNaN( parseFloat( this.get('parameterobjekte').findBy('id', this.get('parameter')).values[0].id ) ) );

      if (this.get('parameterobjekte').findBy('id', this.get('parameter')).values.length > 0) {

        if (isNaN(parseFloat(this.get('parameterobjekte').findBy('id', this.get('parameter')).values[0].id)) === true) {

          return this.get('parameterobjekte').findBy('id', this.get('parameter')).values.sort(function (a, b) {
            var nameA = a.id.toUpperCase(); // Groß-/Kleinschreibung ignorieren
            var nameB = b.id.toUpperCase(); // Groß-/Kleinschreibung ignorieren
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // Namen müssen gleich sein
            return 0;
          });
        } else {

          return this.get('parameterobjekte').findBy('id', this.get('parameter')).values.sort(function (a, b) {
            return parseFloat(a["id"]) - parseFloat(b["id"]);
          });
        }
      }
    }).property('parameterobjekte'),

    actions: {
      filtern: function filtern(_id, _checked) {
        var _this = this;

        if (_checked) {
          var index = this.get('filter').findIndex(function (object) {
            return object.id === _this.get('parameter') && object.value === _id;
          });
          this.get('filter').splice(index, 1);
        } else {
          this.get('filter').push({ id: this.get('parameter'), value: _id });
        }

        this.sendAction("cfilter", this.get('parameter'), _id, _checked);
      }
    }

  });

  exports['default'] = Multiselectbox;

});