define('m07/components/selectbox-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Multiselectbox = Ember['default'].Component.extend({

    tagName: 'div',
    layoutName: 'selectbox-item',
    attributeBindings: [],
    classNames: ['multiSelectBox'],

    watchValueChecked: (function () {

      // console.log("+++++ selectbox-item ::: watchValueChecked +++++");

      this.sendAction('filter', this.get('value').id, this.get('value').checked);
    }).observes('value.checked')

  });

  exports['default'] = Multiselectbox;

});