define('m07/components/screw-tablerow', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Multiselectbox = Ember['default'].Component.extend({

    tagName: 'tr',
    layoutName: 'screw-tablerow',
    attributeBindings: [],

    hideTip: true,

    mouseEnter: function mouseEnter(e) {

      this.set("hideTip", !this.get("hideTip"));

      var xPos = this.element.offsetLeft + this.element.offsetWidth + 64;
      var yPos = this.element.getBoundingClientRect().top + window.scrollY;

      $("#tooltipfenster").append(this.item.ToolTipText);

      $("#tooltipfenster").toggleClass("displayNone");
      $("#tooltipfenster").toggleClass("VM_Okay" + this.item.VM_Okay);
      $("#tooltipfenster").offset({ left: xPos, top: yPos });
    },

    mouseLeave: function mouseLeave(e) {

      this.set("hideTip", !this.get("hideTip"));

      $("#tooltipfenster").text("");
      $("#tooltipfenster").toggleClass("displayNone");
      $("#tooltipfenster").toggleClass("VM_Okay" + this.item.VM_Okay);
    },

    itemID: (function () {

      return this.item.ArtNr.toString().replace(/ /g, "");
    }).property('item'),

    actions: {
      toggleTip: function toggleTip(e) {
        this.set("hideTip", !this.get("hideTip"));

        var xPos = e.element.offsetLeft + e.element.offsetWidth + 32;
        var yPos = e.element.getBoundingClientRect().top + window.scrollY;
        if (this.get('hideTip') === false) {
          $("#tooltipfenster").append(this.item.ToolTipText);
        } else {
          $("#tooltipfenster").text("");
        }
        $("#tooltipfenster").toggleClass("displayNone");
        $("#tooltipfenster").toggleClass("VM_Okay" + this.item.VM_Okay);
        $("#tooltipfenster").offset({ left: xPos, top: yPos });
      },

      setSelectedScrew: function setSelectedScrew(_artNr, _valid, _produktInfo, _cad) {
        this.sendAction('cSetSelectedScrew', _artNr, _valid, _produktInfo, _cad);
      }
    }

  });

  exports['default'] = Multiselectbox;

});